import { Col, Container, Row, i } from "react-bootstrap";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import React, { useEffect, useRef } from "react";

const Header = () => {
	const headerRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			const header = headerRef.current;
			if (header) {
				if (window.pageYOffset > header.offsetTop) {
					header.classList.add("sticky");
				} else {
					header.classList.remove("sticky");
				}
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	const topQuickLinks = [
		{
			// Icon for map
			name: "address",
			icon: "fas fa-map-marker-alt",
			value: "701 S Main, Suite 400 Logan, UT 84321",
			link: "https://www.google.com/maps/place/Adams+Wealth+Advisors/@41.7194058,-111.8379417,17z/data=!3m1!4b1!4m5!3m4!1s0x87547e3a71ed9f29:0x2bb6538c6e75a541!8m2!3d41.7194058!4d-111.8357536",
		},
		{
			name: "address",
			icon: "fas fa-map-marker-alt",
			value: "491 N Bluff St. Ste 200 Saint George, UT 84770",
			link: "https://goo.gl/maps/qzvUEHcRi6xyiG789",
		},
		{
			name: "phone",
			// Icon for phone
			icon: "fas fa-phone",
			value: "(435) 752-1702",
		},
		{
			name: "social",
			// Icon for facebook
			icon: "fab fa-facebook-f",
			link: "https://www.facebook.com/people/Adams-Wealth-Advisors/100057305176483/",
		},
		{
			name: "social",
			// Icon for twitter
			icon: "fab fa-twitter",
			link: "https://mobile.twitter.com/adamsadvisors",
		},
		{
			name: "social",
			icon: "fab fa-linkedin-in",
			link: "https://www.linkedin.com/company/adams-wealth-advisors",
		},
	];

	return (
		<>
			<header id="navbar" ref={headerRef}>
				<Container>
					<Row>
						<Col className="d-flex justify-content-between">
							<div className="site-logo">
								<Link to="/">
									<img
										src="https://adamswealthadvisors.com/app/uploads/2022/07/AdamsWealth-logo-white.svg#logo"
										alt="logo"
									/>
								</Link>
							</div>
							<div className="main-nav d-flex align-items-center">
								{topQuickLinks.map((item, index) => (
									<div
										key={index}
										className="quick-link"
										onClick={() => {
											// Open social in new tab
											// Open address in google maps
											if (item.name === "address") {
												window.open(
													item.link,
													"_blank"
												);
											} else if (item.name === "social") {
												window.open(
													item.link,
													"_blank"
												);
											}
										}}
									>
										<i className={item.icon}></i>
										<span>{item.value}</span>
									</div>
								))}
							</div>
						</Col>
					</Row>
				</Container>
			</header>
		</>
	);
};

export default Header;
