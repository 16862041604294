import React from "react";

import Banner from "../Components/Banner/Banner";

const HomePage = () => {
	return (
		<>
			<Banner />
			{/* <button
				className="hover-button"
				onClick={(evt) => {
					evt.preventDefault();
					const url = "https://app.adamswealthadvisors.com/";
					window.location.href = url;
				}}
			>
				Client Login
			</button> */}
		</>
	);
};

export default HomePage;
