import { Col, Container, Row } from "react-bootstrap";
import Maintenance from "../../assets/images/maintenance.png";
import BannerBG from "./images/banner-bg.png";

const Banner = () => {
	return (
		<section
			className="section-banner"
			style={{ backgroundImage: `url(${BannerBG})` }}
		>
			<Container>
				<Row className="align-items-center">
					<Col xl={6} lg={6}>
						<div className="banner-content wow fadeInLeft">
							<h1>
								The site is currently under maintenance. In the
								meantime, you can access your account by
								clicking the button below.
							</h1>
							<a
								href="#contact-us"
								className="bordered-btn"
								onClick={(evt) => {
									evt.preventDefault();
									const url =
										"https://app.adamswealthadvisors.com/";
									window.location.href = url;
								}}
								style={{
									fontFamily: "Arial, sans-serif",
									marginTop: "20px",
								}}
							>
								Client Login
							</a>
						</div>
					</Col>
					<Col xl={6} lg={6}>
						<div className="banner-thumbnail wow fadeInUp">
							<img
								src={Maintenance}
								alt=""
								style={{ borderRadius: "10px" }}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Banner;
